// src/DownloadButtons.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faApple } from '@fortawesome/free-brands-svg-icons';

const DownloadButtons: React.FC = () => {
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-gray-900 overflow-hidden">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: 'url("https://tronova.azmiproductions.com/img/backy.png")' }} // Replace with your image
      >
        {/* Black overlay with low opacity */}
        <div className="absolute inset-0 bg-black opacity-70" />
      </div>

      <h1 className="text-2xl font-extrabold text-white mb-8 z-10 text-shadow">
        Pocket IIUM
      </h1>
      <div className="space-y-6 z-10">
        {/* Google Play Store Button */}
        <a
          href="https://play.google.com/store/apps/details?id=com.smart.shuttle"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center w-80 h-14 text-lg font-semibold text-white transition-transform transform bg-gradient-to-r from-green-800 to-green-950 rounded-lg shadow-md hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-4 focus:ring-green-400"
        >
          <FontAwesomeIcon icon={faDownload} className="h-6 w-6 mr-3" />
          <span className="font-bold">Download on the Play Store</span>
        </a>
        {/* Apple App Store Button */}
        <a
          href="https://apps.apple.com/my/app/pocket-iium/id6727002220"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center w-80 h-14 text-lg font-semibold text-white transition-transform transform bg-gradient-to-r from-blue-800 to-blue-950 rounded-lg shadow-md hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-4 focus:ring-blue-400"
        >
          <FontAwesomeIcon icon={faApple} className="h-6 w-6 mr-3" />
          <span className="font-bold">Download on the App Store</span>
        </a>
      </div>
    </div>
  );
};

export default DownloadButtons;
